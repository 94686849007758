import Home from '@mui/icons-material/Home';

import config from "../config";
import NavigationLink from "../interfaces/NavigationLink";
import Permissions from "../shared/enums/Permissions";
import CampaignDelight from "../shared/icons/CampaignDelight";
import CustomerCube from "../shared/icons/CustomerCube";
import ImportExport from "../shared/icons/ImportExport";
import Intelliclose from "../shared/icons/Intelliclose";
import Offerio from "../shared/icons/Offerio";
import Reports from "../shared/icons/Reports";
import Retensee from "../shared/icons/Retensee";
import SocialMediaReward from "../shared/icons/SocialMediaReward";
import { Settings } from "@mui/icons-material";
import CommerceConnect from "../shared/icons/CommerceConnect";
import PollIcon from '@mui/icons-material/Poll';

const { dashboardClassic, commerceConnect } = config;

const navConfig: NavigationLink[] = [
  {
    requiredPermissions: [Permissions.OVERVIEW],
    label: 'Home',
    content: `/`,
    externalLink: false,
    icon: Home,
  },
  {
    requiredPermissions: [],
    label: "Customer Cube",
    icon: CustomerCube,
    isNew: true,
    content: [
      {
        content: `/customer-cube/customer-analysis`,
        label: "Customer Analysis",
        requiredPermissions: [Permissions.CUSTOMER_ANALYSIS],
        externalLink: false,
      },
      {
        content: `/customer-cube/store-analysis`,
        label: "Store Analysis",
        requiredPermissions: [Permissions.STORE_ANALYSIS],
        externalLink: false,
      },
      {
        content: `/customer-cube/festival-analysis`,
        label: 'Festival Analysis',
        requiredPermissions: [Permissions.FESTIVAL_ANALYSIS],
        externalLink: false,
      },
      {
        label: 'Product',
        requiredPermissions: [],
        externalLink: false,
        content: [
          {
            content: `/customer-cube/product-analysis`,
            label: "Product Analysis",
            requiredPermissions: [Permissions.PRODUCT_ANALYSIS],
            externalLink: false,
          },
          {
            content: `/customer-cube/product-frequency`,
            label: "Product Frequency",
            requiredPermissions: [Permissions.PRODUCT_FREQUENCY],
            externalLink: false,
          },
          {
            content: `${dashboardClassic}/basket-analysis`,
            label: "Basket Analysis",
            requiredPermissions: [Permissions.BASKET_ANALYSIS],
            externalLink: true,
          },
        ],
      },
      {
        content: `/customer-cube/customer-360`,
        label: "Customer 360",
        requiredPermissions: [Permissions.CUSTOMER_360],
        externalLink: false,
      },
      {
        content: `${dashboardClassic}/migrate-customers`,
        label: "Migrate Customer",
        requiredPermissions: [Permissions.MIGRATE_CUSTOMER],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.LOYALTY],
    label: "RetenSee",
    content: [
      {
        requiredPermissions: [Permissions.LOYALTY],
        label: "Adhoc Points",
        content: `${dashboardClassic}/loyalty-adhoc-points`,
        externalLink: true,
      },
    ],
    externalLink: true,
    icon: Retensee,
  },
  {
    requiredPermissions: [Permissions.SURVEY],
    label: 'Survey',
    content: '/survey/list',
    externalLink: false,
    isNew: true,
    icon: PollIcon,
  },
  {
    requiredPermissions: [Permissions.SOCIAL_MEDIA_REWARDS],
    label: "Social Media Rewards",
    icon: SocialMediaReward,
    content: [
      {
        content: `${dashboardClassic}/social-media-engagements`,
        label: "Rewards Summary",
        requiredPermissions: [Permissions.SOCIAL_MEDIA_REWARDS],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
    label: "Offerio",
    icon: Offerio,
    content: [
      {
        content: `${dashboardClassic}/offers/create`,
        label: "Create Offer",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
      {
        content: `${dashboardClassic}/offers`,
        label: "Offers List",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
      {
        content: `${dashboardClassic}/offers/search`,
        label: "Customer Offers Search",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
      {
        content: `${dashboardClassic}/offers-analytics`,
        label: "Offers Analytics",
        requiredPermissions: [Permissions.OFFERS_MANAGEMENT],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    requiredPermissions: [Permissions.LEAD_CREATE],
    label: "Intelliclose",
    icon: Intelliclose,
    content: [
      {
        requiredPermissions: [Permissions.LEAD_CREATE],
        content: `/leads/create`,
        label: 'Create Lead',
        externalLink: false,
      },
      {
        content: `/leads`,
        label: 'Manage Leads',
        requiredPermissions: [Permissions.LEAD_MANAGE],
        externalLink: false,
      },
      {
        requiredPermissions: [Permissions.LEAD_DASHBOARD],
        content: `/lead-dashboard`,
        label: 'Lead Dashboard',
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    label: "Campaign Delight",
    icon: CampaignDelight,
    requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
    content: [
      {
        label: "Create Cohort",
        content: `${dashboardClassic}/create-cohorts`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: "List Cohorts",
        content: `${dashboardClassic}/manage-cohorts`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: "Create Campaign",
        content: `/campaign/create`,
        requiredPermissions: ["campaign_manage"],
        externalLink: false,
      },
      {
        label: "Manage Campaigns",
        content: `/campaign/list`,
        requiredPermissions: ["campaign_manage"],
        externalLink: false,
      },
      {
        label: "Campaign Analytics",
        content: `${dashboardClassic}/campaign-analytics`,
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
      {
        label: 'Copy Write Templates',
        content: `/campaign/copy-write/templates`,
        requiredPermissions: [Permissions.COPY_WRITE],
        externalLink: false,
      },
      {
        label: 'Email Templates',
        content: [
          {
            label: "Create",
            content: `${dashboardClassic}/email-templates/create`,
            requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
            externalLink: true,
          },
          {
            label: "List",
            content: `${dashboardClassic}/email-templates/list`,
            requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
            externalLink: true,
          },
        ],
        requiredPermissions: [Permissions.CAMPAIGN_MANAGE],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    label: 'Commerce Connect',
    icon: CommerceConnect,
    requiredPermissions: [Permissions.COMMERCE_CONNECT],
    externalLink: true,
    content: `${commerceConnect}`
  },
  {
    label: 'Reports',
    icon: Reports,
    requiredPermissions: [],
    content: [
      {
        label: "Loyalty",
        content: [
          // {
          //   label: 'Tier Downgrade',
          //   content: `${dashboardClassic}/reports/tier-downgrade-report`,
          //   requiredPermissions: [Permissions.TIER_DOWNGRADE_REPORT],
          //   externalLink: true,
          // },
          {
            label: "Liability",
            content: `${dashboardClassic}/reports/loyalty-liability-report`,
            requiredPermissions: [Permissions.LOYALTY_LIABILITY_REPORT],
            externalLink: true,
          },
        ],
        requiredPermissions: [],
        externalLink: true,
      },
      {
        label: "Member",
        content: [
          {
            label: "Transaction",
            content: `${dashboardClassic}/reports/transaction-report`,
            requiredPermissions: [Permissions.TRANSACTION_REPORT],
            externalLink: true,
          },
          {
            label: "Enrollment",
            content: `${dashboardClassic}/reports/member-bill-report`,
            requiredPermissions: [Permissions.MEMBER_BILL_REPORT],
            externalLink: true,
          },
          {
            label: "Fraud",
            content: `${dashboardClassic}/reports/fraud-report`,
            requiredPermissions: [Permissions.FRAUD_REPORT],
            externalLink: true,
          },
        ],
        requiredPermissions: [],
        externalLink: false,
      },
      {
        label: "Lead",
        content: [
          {
            label: "Overall Lead Info",
            content: `${dashboardClassic}/reports/overall-lead-report`,
            requiredPermissions: [Permissions.OVERALL_LEAD_REPORT],
            externalLink: true,
          },
          {
            label: "Store Category",
            content: `${dashboardClassic}/reports/store-category-report`,
            requiredPermissions: [Permissions.STORE_CATEGORY_REPORT],
            externalLink: true,
          },
          {
            label: "Source Efficiency",
            content: `${dashboardClassic}/reports/source-efficiency-report`,
            requiredPermissions: [Permissions.SOURCE_EFFICIENCY_REPORT],
            externalLink: true,
          },
          {
            label: "Store Efficiency",
            content: `${dashboardClassic}/reports/store-efficiency-report`,
            requiredPermissions: [Permissions.STORE_EFFICIENCY_REPORT],
            externalLink: true,
          },
          {
            label: "Store Person Efficiency",
            content: `${dashboardClassic}/reports/store-person-efficiency-report`,
            requiredPermissions: [Permissions.STORE_PERSON_EFFICIENCY_REPORT],
            externalLink: true,
          },
          {
            label: "Product Hierarchy Efficiency",
            content: `${dashboardClassic}/reports/product-hierarchy-efficiency-report`,
            requiredPermissions: [Permissions.PRODUCT_HIERARCHY_EFFICIENCY_REPORT],
            externalLink: true,
          },
        ],
        requiredPermissions: [Permissions.LEAD_CSV_DOWNLOAD],
        externalLink: false,
      },
      {
        label: "Campaign",
        content: `${dashboardClassic}/reports/campaign-report`,
        requiredPermissions: [Permissions.CAMPAIGN_TENANT_REPORT],
        externalLink: true,
      },
      {
        label: "KPI",
        content: `${dashboardClassic}/reports/kpi-report`,
        requiredPermissions: [Permissions.KPI_REPORT],
        externalLink: true,
      },
      {
        label: "Offer",
        requiredPermissions: [Permissions.OFFER_TENANT_REPORT],
        content: `${dashboardClassic}/reports/offer-report`,
        externalLink: true,
      },
      {
        label: "Store Health",
        requiredPermissions: [Permissions.STORE_HEALTH_REPORT],
        content: `${dashboardClassic}/reports/store-health-report`,
        externalLink: true,
      },
      {
        label: "Store KPI",
        requiredPermissions: [Permissions.STORE_KPI_REPORT],
        content: `${dashboardClassic}/reports/store-kpi-report`,
        externalLink: true,
      },
      {
        label: 'Store Status Report',
        content: `/reports/store-status-report`,
        requiredPermissions: [Permissions.STORE_STATUS_REPORT],
        externalLink: false,
      },
    ],
    externalLink: false,
  },
  {
    label: "Import/Export",
    icon: ImportExport,
    requiredPermissions: [Permissions.DATA_MANAGE],
    content: [
      {
        label: "Bill Uploads",
        content: `bill-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: false,
      },
      {
        label: "Upload Data",
        content: `/data-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: false,
      },
      {
        label: "Large Data Uploads",
        content: `${dashboardClassic}/parallel-upload`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: true,
      },
      {
        label: "Download User Data",
        content: `${dashboardClassic}/user-data-download`,
        requiredPermissions: [Permissions.DATA_MANAGE],
        externalLink: true,
      },
    ],
    externalLink: false,
  },
  {
    label: "Settings",
    icon: Settings,
    requiredPermissions: [Permissions.DATA_MANAGE],
    content: `/settings`,
    externalLink: false,
  },
];

export default navConfig;
